import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import CollectionPage from '~/components/Collection/Page'
import ContentPage from '~/components/Content/Page'
import useBanners from '~/hooks/components/use-banners'

const ShopWineGifts = ({ data }) => {
  const { collection, page } = data
  const { slug } = page

  const banner = useBanners(slug)

  return (
    <Layout banner={banner}>
      <Metadata />
      <CollectionPage collection={collection} />
      <ContentPage page={page} />
    </Layout>
  )
}

export default ShopWineGifts

export const query = graphql`
  query ShopWineGiftsPage($locale: String) {
    collection: contentfulCollection(
      slug: { eq: "shop-gifts" }
      node_locale: { eq: $locale }
    ) {
      ...CollectionDetailsFragment
    }
    page: contentfulPage(
      slug: { eq: "wine-gifts" }
      node_locale: { eq: $locale }
    ) {
      ...ContentPageFragment
    }
  }
`
